<template lang="pug">
  .app-nested-routes-tabs
    BTabs(
      fill
      @activate-tab="handleTabChange"
    )
      BTab(
        v-for="route in routes"
        v-if="!route.disabled"
        :title-link-class="kebabCase(route.name)"
        :active="isCurrentRoute(route)"
        :title="route.title"
        :key="route.name"
        @click="handleLinkClick(route)"
      )
    RouterView
</template>

<script>
  import { kebabCase } from "lodash-es"
  import { BTabs, BTab } from "bootstrap-vue"

  export default {
    components: {
      BTabs,
      BTab
    },

    props: {
      // [
      //   {
      //     name: "BasicSettings",
      //     title: "Basic Settings"
      //   },
      //   {
      //     name: "ByPeriod",
      //     title: "By Period"
      //   }
      // ]
      routes: {
        type: Array,
        default: () => []
      },
      defaultPrevented: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      kebabCase,

      isCurrentRoute(route) {
        return this.$route.name === route.name
      },

      handleLinkClick(route) {
        if (this.isCurrentRoute(route)) return

        this.$router.push({ name: route.name })
      },

      handleTabChange(_newTabIndex, _oldTabIndex, event) {
        if (this.defaultPrevented) event.preventDefault()
      }
    }
  }
</script>

<style lang="sass" scoped></style>
